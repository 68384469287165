// exclude-from-index
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Text from '../../../../BuildingBlocks/Text';
import { StyledOrgSelect, StyledMenuText, StyledArrow, StyledSingleOrg } from './OrgSelect.styles';

export default class OrgSelect extends Component {
  static propTypes = {
    /**
     * Array of organization objects containing their ID and front facing name. Needs to be passed with usersCurrentOrganization.
     */
    organizations: PropTypes.arrayOf(PropTypes.shape),
    /**
     * String ID of the users currently assigned organization, needs to match an organization passed within organizations array.
     */ userCurrentOrganization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Function passed to callback so we can sync all areas showing current organization when users select a new one.
     */
    organizationChange: PropTypes.func,
    /**
     * Numerical value 360deg to determine rotation of the carrot icon next to the select.
     */
    iconRotation: PropTypes.number,
    /**
     * Bool Letting us know touch is enabled.
     */
    isTouchDevice: PropTypes.bool,
    /**
     * Bool determining whether to show the carrot or not.
     */
    showIcon: PropTypes.bool,
    /**
     * Passed function to callback on once material select menu is closed, so we can ensure user is still in dekstop flyout on hover or not. used only on Desktop.
     */
    hoverCheck: PropTypes.func
  };

  static defaultProps = {
    organizations: [],
    userCurrentOrganization: '',
    organizationChange: () => {},
    iconRotation: 0,
    isTouchDevice: false,
    showIcon: true,
    hoverCheck: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedOrg: props.userCurrentOrganization,
      selectOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeSelect = this.closeSelect.bind(this);
    this.openSelect = this.openSelect.bind(this);
  }

  // used to update the current selected organization once callback has executed and new value is passed back.
  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedOrg } = prevState;
    const { userCurrentOrganization } = nextProps;

    if (selectedOrg !== userCurrentOrganization) {
      return { selectedOrg: userCurrentOrganization };
    }
    return null;
  }

  // function returns the newly selected organization to the callback.
  handleChange(e) {
    const { organizations, organizationChange } = this.props;
    const { selectedOrg } = this.state;

    // if the new one isn't the same as the old; update.
    if (e.target.value !== selectedOrg) {
      const changedOrg = organizations.find(organization => organization.id === e.target.value) || 'unknown';

      organizationChange(changedOrg);
    }
  }

  // function to close the material select and trigger desktop hover call back check.
  closeSelect() {
    const { hoverCheck, isTouchDevice } = this.props;
    this.setState({ selectOpen: false });

    // only used in desktop and not touch ones.
    if (!isTouchDevice) hoverCheck();
  }

  // function used to open the select.
  openSelect() {
    this.setState({ selectOpen: true });
  }

  truncate(string, maxChars = 30) {
    return string.length > maxChars ? `${string.substr(0, maxChars)}...` : string;
  }

  renderOrg() {
    const { organizations, showIcon, iconRotation } = this.props;
    const { selectedOrg, selectOpen } = this.state;

    if (organizations.length > 0 && selectedOrg) {
      if (organizations.length === 1) {
        return (
          <StyledSingleOrg>
            <Text.CopyParagraph weight="bold" noMargins>
              {this.truncate(organizations[0].name)}
            </Text.CopyParagraph>
          </StyledSingleOrg>
        );
      }

      return (
        <StyledOrgSelect
          value={selectedOrg !== null ? selectedOrg : ''}
          onChange={this.handleChange}
          onOpen={this.openSelect}
          onClose={this.closeSelect}
          open={selectOpen}
          IconComponent={() => (showIcon ? <StyledArrow selectedIcon="arrow" rotate={iconRotation} /> : null)} // eslint-disable-line
        >
          {[
            organizations.map(organization => (
              <MenuItem value={organization.id}>
                <StyledMenuText>{this.truncate(organization.name, 25)}</StyledMenuText>
              </MenuItem>
            ))
          ]}
        </StyledOrgSelect>
      );
    }

    return null;
  }

  render() {
    return <>{this.renderOrg()}</>;
  }
}
